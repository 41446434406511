<template>
  <div style="position: absolute; width: 100%; height: 100%; overflow: hidden">
    <iframe :src="src" style="height: 100%; width: 100%"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      src: "",
    };
  },
  created() {
    let query = this.$route.query;
    if (!this.$f.isNotNull(query.lookDress)) {
      this.$alert("获取直播信息失败,请重试", "小筑教育", {
        confirmButtonText: "确定",
        callback: () => {
          window.history.back()
        },
      });
    } else {
      this.src = query.lookDress
    }
  },
};
</script>

<style scoped>
</style>